import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, Row } from 'reactstrap';
import { API_URL, API_HEADER} from './../../../config';
import  axios from 'axios';
import {connect} from "react-redux";
import {createSelector} from "reselect";
import {updateUser} from "./../../../service/actions/user-action";
import SimpleReactValidator from "simple-react-validator";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './../../../assets/img/brand/zoominLogo.svg'

class ResetPasswordUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      msg:'',
    };
    this.validator = new SimpleReactValidator({
      confirmpass: {
        message: 'Confirm password must be same as password.',
        rule: function (val, options) {
          return val === options[0]
        }
      },
    });
  }

  handleSubmit(event) {
    let token = this.props.match.params.token;
    event.preventDefault();
    const {history} = this.props;
    let reqBody = {
      password: this.state.password,
      reset_password_token : token
    };
    if (this.validator.allValid()) {
      axios.post(`${API_URL}reset-password-user/${token}`, reqBody, API_HEADER).then((response) => {
        if (response.status === 200) {

          if (response.data.status === 0) {
              history.push('/greetings');
          } else {
            this.setState({msg: response.data.message});
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000
            });
          }
        }
        else {
          this.setState({msg: response.data.message});
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000
          });
        }
      }, err => {
        this.setState({msg: 'Something went wrong!'})
      })
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    return (
      <div className="app zoomin-theme flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col className="col-md-7 col-sm-10 col-xs-10 col-lg-6 col-xl-5">
              <CardGroup>
                <Card className="p-4 bg-login">
                  <CardBody>
                    <Form onSubmit={evt => this.handleSubmit(evt)}>
                      <div className="text-center">
                        <img src={logo} width={200} height={100} alt="zoomin logo"/>
                      </div>
                      <h5 className="text-center fp mb-5">Reset Password</h5>
                      <p className="text-muted text-center">Please enter new password</p>
                      <InputGroup className="mb-3">
                        {/*<InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"/>
                          </InputGroupText>
                        </InputGroupAddon>*/}
                        <Input type="password" placeholder="Password" name="password" className="input-box-login"
                               value={this.state.password} onChange={evt => this.setState({ password: evt.target.value })}
                        />
                      </InputGroup>
                      {this.validator.message('password',this.state.password, 'required|min:6', 'text-danger')}

                      <InputGroup className="mb-3">
                        {/*<InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"/>
                          </InputGroupText>
                        </InputGroupAddon>*/}
                        <Input type="password" name="confirm_pass" placeholder="Confirm Password" className="input-box-login"
                               onChange={evt => this.setState({ confirm_pass: evt.target.value })}/>
                      </InputGroup>
                      {this.validator.message('confirm_pass',this.state.confirm_pass, 'confirmpass:'+this.state.password, 'text-danger')}

                      <p className="text-danger" color="danger">{this.state.msg}</p>
                      <Row>
                        <Col xs="12">
                          <Button className="px-4 submit-btn bg-newcol col-12 border-0 font-weight-bold mt-2">Submit</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
        <ToastContainer/>
      </div>
    );
  }
}
const userSelector = createSelector(
  state => state.user,
  user => user,
);

const mapStateToProps = createSelector(
  userSelector,
  (user) => ({
    user
  })
);

const mapActionToProps = {
  onUserUpdate: updateUser
};

export default connect(mapStateToProps, mapActionToProps)(ResetPasswordUser);
