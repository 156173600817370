import React, {Component} from 'react';
import {ADMIN_API_HEADER, API_HEADER} from "../config";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Api from "../service/Api";
import {withRouter} from 'react-router-dom'
import axios, {AxiosError as error} from "axios/index";
import 'react-input-range/lib/css/index.css'
import "./stylesheet.css"
import fontFamily from "../service/FontFamily";
import { Textfit } from 'react-textfit';
import AvatarEditor from 'react-avatar-editor'
import InputRange from "react-input-range";
import Helpers from "../service/Helpers";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import {confirmAlert} from "react-confirm-alert";


class OrdersEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layoutJson: [],
      slug: "",
      version: "",
      imageEditor: false, editStatus: true
    };
    this.validator = new SimpleReactValidator();
    this.validatorOrderStatus = new SimpleReactValidator();
  }
  componentDidMount(){
    let id = this.props.match.params.orderId;
    if (id) {
      let reqBody = {
        id: id,
      };
      Api.getSubOrderJson(reqBody, ADMIN_API_HEADER).then((response) => {
        if (response.status === 200) {
          if (response.data.status === 0) {
            let orderDetail = response.data.data[0]
            let orderJson = orderDetail.order_json.pages
            this.setState({
              orderJsonData: JSON.stringify(orderJson),
              orderDetail
            })
            let templateUrl = `https://s3.ap-south-1.amazonaws.com/zoomin-new/live-product/${orderDetail.order_json.id}/${orderDetail.order_json.version}/template.json`
            axios.get(templateUrl, {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "cache-control": "no-cache"
              }
            }).then(templateResponse => {
              if (templateResponse.status === 200) {
                let layoutJsonData = templateResponse.data.theme ? templateResponse.data.theme.filter(arr=>arr.themeid===orderDetail.order_json.themeid)[0]: templateResponse.data.pages
                let layoutJsonTheme = [], pageJson = [], layoutJson = []
                if(layoutJsonData.cover){
                  layoutJsonTheme.push(layoutJsonData.cover[0])
                }
                layoutJsonTheme = layoutJsonTheme.concat(layoutJsonData.layouts ? layoutJsonData.layouts : layoutJsonData)
                let pages = orderDetail.order_json.pages
                pages.forEach((page)=>{
                  layoutJson.push(layoutJsonTheme.filter(arr=> arr.id===page.id)[0])
                })
                fontFamily(templateResponse.data.template.id, templateResponse.data.template.version)
                let templateFrameWidth, templateFrameHeight
                templateFrameWidth = templateResponse.data.template.size[layoutJsonData && layoutJsonData.orientation && layoutJsonData.orientation === "landscape" ? 1 : 0]
                templateFrameHeight = templateResponse.data.template.size[layoutJsonData && layoutJsonData.orientation && layoutJsonData.orientation === "landscape" ? 0 : 1]
                this.setState({pageJson: pages, layoutJson, templateJson: templateResponse.data.template, slug: templateResponse.data.template.id, version: templateResponse.data.template.version, templateFrameWidth, templateFrameHeight})

              }
            })


          }
          else {
            this.setState({msg: response.data.message, editStatus: false});
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000
            });
          }
        }
        else {
          this.setState({msg: response.data.message, editStatus: false});
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000
          });
        }
      })
    }
  }
  componentWillUpdate(nextProps, nextState, nextContext) {
    if(nextState.pageJson && nextProps.match.params.i && nextProps.match.params.j && (nextProps.match.params.i !== this.state.iPos || nextProps.match.params.j !== this.state.jPos)){
      this.setState({
        iPos: nextProps.match.params.i,
        jPos: nextProps.match.params.j
      })
      let canvasScale = nextState.layoutJson[nextProps.match.params.i].placeholders[nextProps.match.params.j].frame[2] / nextState.layoutJson[nextProps.match.params.i].placeholders[nextProps.match.params.j].frame[3]

      fetch(`https://cdn.filestackcontent.com/AS6IcbWmTSZK2jYLeo85nz/rotate=deg:exif/imagesize/${nextState.pageJson[nextProps.match.params.i].placeholders[nextProps.match.params.j].photourl.replace('https://cdn.filestackcontent.com/', '')}`)
        .then(res => res.json())
        .then((data) => {
          let localImage=nextState.pageJson[nextProps.match.params.i].placeholders[nextProps.match.params.j]
          let value = localImage.zoomScale;
          let x=localImage.frame[0], y=localImage.frame[1], cropWidth=localImage.frame[2], cropHeight=localImage.frame[3]
          if (localImage.degree === 90) {
            let ox = localImage.frame[0]
            let oy = localImage.frame[1]
            x = oy;
            y = data.height-(ox + cropWidth);
            cropWidth = localImage.frame[3];
            cropHeight = localImage.frame[2];
          }
          else if (localImage.degree === 180) {
            let ox = x
            let oy = y
            x = data.width - (cropWidth + ox);
            y = (data.height - (cropHeight + oy));
          }
          else if (localImage.degree === 270) {
            let ox = x
            let oy = y
            x = data.width - (cropHeight + oy);
            y = ox;
            cropWidth = localImage.frame[3];
            cropHeight = localImage.frame[2];
          }
          x = x / data.width
          y = y / data.height
          let cWidth = cropWidth / data.width
          let cHeight = cropHeight / data.height
          let centX = cWidth / 2;
          let centY = cHeight / 2;
          let position
          if(localImage.frame[0]===0 && localImage.frame[1]===0 && localImage.frame[2]===0 && localImage.frame[3]===0){
            position = {x: 0.5, y: 0.5}
          }
          else{
            position = {x: x + centX, y: y + centY}
          }
          this.setState({
            image: localImage.photourl.replace('https://cdn.filestackcontent.com/', ''),
            position: position,
            scale: value,
            value: value,
            rotate: localImage.degree,
            width: data.width,
            height: data.height,
            canvasScale: canvasScale,
            imageEditor: true
          })
        })
    }
    if(!this.props.match.params.i && !this.props.match.params.j && nextProps.match.params.i && nextProps.match.params.j){
      this.setState({scroll: window.scrollY})
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(this.props.location.pathname!==nextProps.location.pathname && !nextProps.match.params.i){
      this.setState({
        image: "",
        position: "",
        scale: "",
        value: "",
        rotate: "",
        width: "",
        height: "",
        canvasScale: "",
        iPos: "",
        jPos: ""
      })
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    let pageWidth = document.getElementsByClassName("editor-content")
    if(pageWidth[0] && prevState.widthPage!==pageWidth[0].clientWidth){
      this.setState({widthPage: pageWidth[0].clientWidth})
    }
    if(this.state.orderJsonData !== JSON.stringify(this.state.pageJson) && this.state.generatePdf===false){
      this.setState({generatePdf: true})
    }
    if(this.state.orderJsonData !== JSON.stringify(this.state.pageJson) && this.state.reprocessOrder===false){
      this.setState({reprocessOrder: true})
    }
    if(!this.props.match.params.i && !this.props.match.params.j && prevProps.match.params.i && prevProps.match.params.j){
      console.log(prevProps.scroll, this.state.scroll);
      window.scrollTo(0, this.state.scroll)
    }
  }
  redirectImageEditor=(i, j, width, height)=>{
    this.props.history.push("/preview/"+this.props.match.params.orderId+"/edit/"+i+"/"+j)
  }
  handlePositionChange = position => {
    this.setState({position})
  }
  setEditorRef = (editor) => this.editor = editor
  rotateLeft=()=> {
    this.setState({
      value: 1,
      position: {x: 0.5, y: 0.5},
      scale: 1,
    })
    if (this.state.rotate !== 0) {
      this.setState({
        rotate: this.state.rotate - 90
      })
    } else {
      this.setState({
        rotate: 270
      })
    }
  }
  rotateRight=()=> {
    this.setState({
      value: 1,
      position: {x: 0.5, y: 0.5},
      scale: 1,
    })
    if (this.state.rotate !== 270) {
      this.setState({
        rotate: this.state.rotate + 90
      })
    } else {
      this.setState({
        rotate: 0
      })
    }

  }
  clickSave=()=> {
    if (this.editor) {
      const data = this.editor.getCroppingRect()
      let x = data.x;
      let y = data.y;
      let cropWidth = data.width;
      let cropHeight = data.height;
      let width = this.state.width;
      let height = this.state.height;
      let scale = this.state.value;
      let rotate = this.state.rotate;
      let imageResize = Helpers.imageResize(rotate, width, height, x * width, y * height, cropWidth * width, cropHeight * height)
      if ((imageResize.x || imageResize.x === 0) && (imageResize.y || imageResize.y === 0) && imageResize.cropWidth && imageResize.cropHeight) {
        let arr = this.state.pageJson;
        arr[this.state.iPos].placeholders[this.state.jPos].frame = [
          parseInt(imageResize.x),
          parseInt(imageResize.y),
          parseInt(imageResize.cropWidth),
          parseInt(imageResize.cropHeight)
        ]
        arr[this.state.iPos].placeholders[this.state.jPos].degree = rotate;
        arr[this.state.iPos].placeholders[this.state.jPos].zoomScale = scale;
        this.setState({
          pageJson: arr,
          image: "",
          position: "",
          scale: "",
          value: "",
          rotate: "",
          width: "",
          height: "",
          canvasScale: "",
          imageLoaded: false
        })
        this.props.history.push(`/preview/${this.props.match.params.orderId}`)
      }
    }
  }
  clickSavePageJson=()=>{
    let order_json = this.state.orderDetail.order_json
    order_json.pages = this.state.pageJson
    let reqBody = {id: this.props.match.params.orderId, order_json: order_json}
    Api.updateSubOrderJson(reqBody, ADMIN_API_HEADER).then((response) => {
      if (response.status === 200) {
        if (response.data.status === 0) {
          this.setState({orderJsonData: JSON.stringify(this.state.pageJson)})
        }
        else {

          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000
          });
        }
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000
        });
      }
    }).catch((error)=>{
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000
      });
    })
  }
  onImageReady = () =>{
    this.setState({imageLoaded : true})
  }
  generatePdf = () => {
      let reqBody = {
        sub_order_id: this.props.match.params.orderId,
      };
    this.setState({generatePdf: false})
      Api.generatePDF(reqBody, ADMIN_API_HEADER).then((response) => {
        this.setState({dataToCome: 1})
        if (response.status === 200) {
          if (response.data.status === 0) {
            for (let i = 0; i < response.data.data.pdf_url.length; i++) {
              setTimeout(function(path){
                window.open(path)
              }, 200, response.data.data.pdf_url[i]);
            }
          } else {
            toast.error(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000
            });
          }

        } else {
          this.setState({
            submit_btn: true
          });
          this.setState({msg: response.data.message});
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000
          });
        }
      })
  };
  changeStatus=()=>{
    this.setState({
      changeSubOrderNote: "",
      modal: !this.state.modal,
    });
    this.validatorOrderStatus.hideMessages()
  }
  handleChangeSubOrderStatus = (e)=>{
    e.preventDefault();
    let id = this.state.orderDetail.id
    let reqBody= {
      id: parseInt(id),
      order_status: "reprocessed",
      notes: this.state.changeSubOrderNote
    }
    if(this.validatorOrderStatus.allValid()){
      this.setState({modal: false})
      confirmAlert({
        title: 'Confirm',
        message: `Are you sure you want to change order status?`,
        buttons: [
          {
            label: `Yes`,
            onClick: () => {
              this.setState({reprocessOrder: false})
              Api.changeSubOrderStatus(reqBody, ADMIN_API_HEADER).then((response) => {
                if (response.status === 200) {
                  if (response.data.status === 0) {
                    toast.success(response.data.message, {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 1500
                    });
                  }
                  else {
                    toast.error(response.data.message, {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 3000
                    });
                  }
                }
                else {
                  this.setState({msg: response.data.message});
                  toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                  });
                }
              })
            }
          },
          {
            label: 'No',
            onClick: () => {
              this.setState({
                changeSubOrderNote: ""
              });
            }
          }
        ]
      })
    }
    else{
      this.validatorOrderStatus.showMessages();
      this.forceUpdate();
    }
  }
  handleSubOrder=(event, name)=>{
    this.setState({[name]: event.target.value}, ()=>{

    })
  }
  render() {
    const {pageJson, layoutJson, templateJson, slug, version, templateFrameWidth, templateFrameHeight, widthPage, orderDetail, orderJsonData} = this.state
    const {canvasScale, template, position, scale, rotate, value, iPos, jPos, generatePdf, reprocessOrder} = this.state;
    let defaultValue = window.outerHeight/3.2
    if(canvasScale < 1){
      defaultValue = window.outerHeight/2.4
    }
    else if(canvasScale === 1){
      defaultValue = window.outerHeight/2.3
    }
    else if(canvasScale > 2){
      defaultValue = window.outerHeight/ 5.2
    }
    let width, height;
    if (rotate === 90 || rotate === 270) {
      width = defaultValue ;
      height = defaultValue * canvasScale
    } else {
      width = defaultValue * canvasScale
      height = defaultValue
    }
    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.changeStatus} className={this.props.className}
               unmountOnClose={this.state.unmountOnClose}>
          <ModalHeader
            toggle={this.toggle}>Change Order Status</ModalHeader>
          <ModalBody>
            <form onSubmit={evt => this.handleChangeSubOrderStatus(evt)}>
              <div className={"row"}>
                <div className={"col-12 form-group"}>
                  <label>Add note</label>
                  <textarea className={"form-control"} value={this.state.changeSubOrderNote} onChange={(e)=>this.handleSubOrder(e, "changeSubOrderNote")}/>
                  <div className={"validation-alert"}>
                    {this.validatorOrderStatus.message('Note', this.state.changeSubOrderNote, 'required', 'text-danger')}
                  </div>
                </div>
                <div className="col-md-12 form-group text-center">
                  <button type="submit"
                          className="mt-lg-4 mr-1 btn bg-newcol expand-right">Submit
                  </button>
                  <button type="button" disabled="" onClick={this.changeStatus}
                          className="mt-lg-4 mr-1 btn btn-dark expand-right">Cancel
                  </button>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
        {this.state.editStatus ? <div>
        {(!this.props.match.params.i || !this.props.match.params.j)  &&
          <div className={"row col-11 col-sm-10 col-md-7 mt-3 ml-auto mr-auto editor-product-content"}>
            <div className={"col-sm-6 mb-2"}>
              <b>Name:</b> {templateJson && templateJson.name}
            </div>
            <div className={"col-sm-6 mb-2"}>
              <b>Sub Order Id:</b> {orderDetail && orderDetail.sub_order_id}
            </div>
            {layoutJson && layoutJson.map((page, i)=>{
              let templateWidth, templateHeight
              if(page.orientation && page.orientation === "landscape"){
                templateWidth = templateFrameHeight
                templateHeight = templateFrameWidth
              }
              else{
                templateWidth = templateFrameWidth
                templateHeight = templateFrameHeight
              }
              let bgImage = "https://s3.ap-south-1.amazonaws.com/zoomin-new/live-product/"+slug+"/"+version+"/assets_client/pages/"+page.id+".png"
              return (<div className={`${layoutJson.length > 1 ? "col-6" : "col-12 col-sm-7 ml-auto mr-auto"} mb-2 mt-2`}>
                <div className={"editor-content"}>
                  <img className={"editor-background-image"} src={bgImage}/>
                  {page.placeholders &&
                  page.placeholders.map((placeholders, j)=>{
                    let pageBook=pageJson[i].placeholders[j]
                    let percentageWidth
                    if(i===0 && page.placeholders[j].displayTruncatingOffset){
                      percentageWidth = (((placeholders.frame[2] - page.placeholders[j].displayTruncatingOffset[2]) / templateWidth) * 100) + '%';
                    }else {
                      percentageWidth = ((placeholders.frame[2] / templateWidth) * 100) + '%';
                    }

                    let percentageHeight
                    if(i===0 && page.placeholders[j].displayTruncatingOffset){
                      percentageHeight = (((placeholders.frame[3] - page.placeholders[j].displayTruncatingOffset[3]) / templateHeight) * 100) + '%';
                    }else {
                      percentageHeight = ((placeholders.frame[3] / templateHeight) * 100) + '%';
                    }
                    let marginLeft
                    if(i===0 && page.displayTruncatingOffset){
                      marginLeft = (((placeholders.frame[0] - page.displayTruncatingOffset[0]) / templateWidth) * 100) + '%';
                    }
                    else if(i===0 && page.placeholders[j].displayTruncatingOffset){
                      marginLeft = (((placeholders.frame[0] - page.placeholders[j].displayTruncatingOffset[0]) / templateWidth) * 100) + '%';
                    }
                    else{
                      marginLeft = ((placeholders.frame[0] / templateWidth) * 100) + '%';
                    }
                    let marginTop
                    if(i===0 && page.placeholders[j].displayTruncatingOffset){
                      marginTop = (((placeholders.frame[1] - page.placeholders[j].displayTruncatingOffset[1]) / templateHeight) * 100) + '%';
                    }else{
                      marginTop = ((placeholders.frame[1] / templateHeight) * 100) + '%';
                    }

                    let deg, imageWidth, imageHeight, cropHeight, cropWidth, x, y, image;
                    if(placeholders.type==="photo") {
                      image = pageBook.photourl;
                      deg=pageBook.degree;
                      x=pageBook.frame[0];
                      y=pageBook.frame[1];
                      cropWidth=pageBook.frame[2];
                      cropHeight=pageBook.frame[3];
                      return(
                        <img
                          onClick={()=>this.redirectImageEditor(i, j, placeholders.frame[2], placeholders.frame[3])}
                          className={"image-placeholder"}
                          i={j}
                          style={{width: percentageWidth, height: percentageHeight, left: marginLeft, top: marginTop}}
                          src={cropWidth===0 && cropHeight === 0 ? "https://process.filestackapi.com/rotate=deg:exif/resize=width:600/"+image: "https://process.filestackapi.com/AS6IcbWmTSZK2jYLeo85nz/rotate=deg:exif/rotate=deg:"+deg+"/crop=dim:["+x+","+y+","+cropWidth+","+cropHeight+"]/resize=width:600/"+image}
                        />
                      )

                    }
                    else if(placeholders.type==="text"){
                      let fontSize = (widthPage/(templateWidth * 37.7952755906)) * ((placeholders.fontSize > 60 ? placeholders.fontSize/ 1.5:  placeholders.fontSize))
                      let minFontSize = placeholders.minFontSize ? ((widthPage/(templateWidth * 37.7952755906)) * (placeholders.minFontSize)): fontSize/2
                      return(
                        <Textfit
                          mode={placeholders.numberOfLines===1 ? "single":"multiple"}
                          className="text-placeholder" max={fontSize} min={minFontSize < 1 ? 1 : minFontSize} style={{
                          width: percentageWidth,
                          height: percentageHeight,
                          whiteSpace: `${placeholders.numberOfLines===1?"": ""}`,
                          wordWrap: `${placeholders.numberOfLines===1? "": "break-word"}`,
                          top: `calc(${marginTop})`,
                          left: marginLeft,
                          fontFamily: `${placeholders.fontName}`,
                          color: placeholders.color,
                          // paddingTop:widthPage/(template.size[0] * 37.7952755906)  >= 1 ? `calc(${fontSize -2}px)` : "",
                          border: "none",
                          outline: "none",
                          background: "transparent",
                          lineHeight: "normal",
                          textTransform: placeholders.textCapitalization==="all" ? "uppercase" : "",
                          textAlign: placeholders.textalign,
                        }}>{pageBook.text ? pageBook.text : "Text here"}</Textfit>
                      )
                    }
                    return ""
                  })
                  }
                </div>
              </div>)
            })}
            <div className={"editor-bottom-bar"}>
              <div className={"col-11 col-sm-10 col-md-7 ml-auto mr-auto row justify-content-between"}>
                <div className={"col-auto row"}>
                  <div className={"col-auto"}>
                  <button className="btn btn-block btn-danger" disabled={orderJsonData !== JSON.stringify(pageJson)} onClick={()=>this.generatePdf()}>Generate PDF</button>
                  </div>
                  <div className={"col-auto"}>
                  <button className="btn btn-block btn-danger" disabled={orderJsonData !== JSON.stringify(pageJson)} onClick={()=>this.changeStatus()}>Reprocess</button>
                  </div>
                </div>
                <div className={"col-auto"}>

                </div>
                <div className={"col-auto"}>
                  <button className="btn btn-block btn-danger" disabled={orderJsonData === JSON.stringify(pageJson)} onClick={()=>this.clickSavePageJson()}>Save Order</button>
                </div>
              </div>
            </div>
          </div>
        }
        {
          (this.props.match.params.i && this.props.match.params.j) &&
            <>
          <div className={"row col-11 col-sm-10 col-md-7 ml-auto mr-auto mt-5"}>
            <AvatarEditor
              ref={this.setEditorRef}
              image={`https://cdn.filestackcontent.com/AS6IcbWmTSZK2jYLeo85nz/rotate=deg:exif/${this.state.image}`}
              width={width}
              height={height}
              border={0}
              className={"image-editor"}
              position={position}
              onPositionChange={()=>this.handlePositionChange()}
              onImageReady={this.onImageReady}
              scale={scale}
              rotate={rotate}
            />
          </div>
              <div>
                <div className={"image-editing-controller"}>
                  <span onClick={()=>this.rotateLeft()}><img className="icon" src="https://d29hh3cevg0gwl.cloudfront.net/images/rotate-left.png" alt={"rotate-left"}/></span>
                  <span onClick={()=>this.rotateRight()}><img className="icon" src="https://d29hh3cevg0gwl.cloudfront.net/images/right.png" alt={"rotate-right"}/></span>
                </div>
              </div>
            <div className={"input-range"} style={{marginTop: '40px', marginLeft: 'auto', marginRight: 'auto'}}>
              <InputRange
              step={0.1}
              maxValue={6}
              minValue={1}
              value={Math.round(value * 10) / 10}
              onChange={value => {
              this.setState({
              value,
              scale: value
            })
            }}/>
            </div>
              <div className={"editor-bottom-bar"}>
                <div className={"col-11 col-sm-10 col-md-7 ml-auto mr-auto row"}>
                  <div className={"col-auto ml-auto"}>
                    <button className="btn btn-block btn-danger" onClick={()=>this.clickSave()}>Save Image</button>
                  </div>
                </div>
              </div>
          </>
        }
        </div>
          :
          <p className={"text-center mt-5"}><b>You can't edit this order</b></p>
        }
        <ToastContainer/>
      </div>
    );
  }
}

export default withRouter(OrdersEditor);
