import React from 'react';
import Loadable from 'react-loadable'
import Helpers from "./service/Helpers";
import JWT from "jsonwebtoken";

function Loading() {
  return <div>Loading...</div>;
}

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});

const Admin = Loadable({
  loader: () => import('./views/Admin/List'),
  loading: Loading,
});

const AdminAdd = Loadable({
  loader: () => import('./views/Admin/Add'),
  loading: Loading,
});

const Coupon = Loadable({
  loader: () => import('./views/Coupon/List'),
  loading: Loading
});

const CouponAdd = Loadable({
  loader: () => import('./views/Coupon/Add'),
  loading: Loading
});

const Referral = Loadable({
  loader: () => import('./views/Referral/List'),
  loading: Loading
});

/*const ReferralAdd = Loadable({
  loader: () => import('./views/Referral/AddCouponOffers'),
  loading: Loading
});*/

const Partner = Loadable({
  loader: () => import('./views/Partner/List'),
  loading: Loading,
});

const PartnerAdd = Loadable({
  loader: () => import('./views/Partner/Add'),
  loading: Loading,
});

const Campaign = Loadable({
  loader: () => import('./views/Campaign/List'),
  loading: Loading,
});

const CampaignAdd = Loadable({
  loader: () => import('./views/Campaign/Add'),
  loading: Loading,
});

const User = Loadable({
  loader: () => import('./views/User/List'),
  loading: Loading,
});

const UserAdd = Loadable({
  loader: () => import('./views/User/Add'),
  loading: Loading,
});

const Products = Loadable({
  loader: () => import('./views/Product/List'),
  loading: Loading,
});

const Orders = Loadable({
  loader: () => import('./views/Order/List'),
  loading: Loading,
});

const OrdersAdd = Loadable({
  loader: () => import('./views/Order/Add'),
  loading: Loading,
});

const SubOrder = Loadable({
  loader: () => import('./views/Order/View/SubOrder'),
  loading: Loading,
});

const ProductsAdd = Loadable({
  loader: () => import('./views/Product/Add'),
  loading: Loading,
});

const Promobanner = Loadable({
  loader: () => import('./views/Promobanners/List'),
  loading: Loading
});

const PromobannerAdd = Loadable({
  loader: () => import('./views/Promobanners/Add'),
  loading: Loading
});

const WalletBannerAdd = Loadable({
  loader: () => import('./views/Promobanners/Add/WalletBannerAdd'),
  loading: Loading
});



const ChangePassword = Loadable({
  loader: () => import('./views/Admin/ChangePassword'),
  loading: Loading
});

const Configuration = Loadable({
  loader: () => import('./views/Configuration'),
  loading: Loading
});

const Couriers = Loadable({
  loader: () => import('./views/Couriers/List'),
  loading: Loading
});
/*
const CouriersAdd = Loadable({
  loader: () => import('./views/Couriers/AddCouponOffers'),
  loading: Loading
});*/

const Shipping = Loadable({
  loader: () => import('./views/Shipping'),
  loading: Loading
});

const PostalCode = Loadable({
  loader: () => import('./views/Postalcode/List'),
  loading: Loading
});

const PostalCodeAdd = Loadable({
  loader: () => import('./views/Postalcode/Add'),
  loading: Loading
});

const Reports = Loadable({
  loader: () => import('./views/Reports/List'),
  loading: Loading
});

const Offers = Loadable({
  loader: () => import('./views/Offers/List'),
  loading: Loading
});

const CouponOffersAdd = Loadable({
  loader: () => import('./views/Offers/AddCouponOffers/AddCouponOffers'),
  loading: Loading
});

const PaymentOffersAdd = Loadable({
  loader: () => import('./views/Offers/AddPaymentOffers'),
  loading: Loading
});

const CardOffersAdd = Loadable({
  loader: () => import('./views/Offers/AddCardOffers/AddCardOffer'),
  loading: Loading
});

const Financials = Loadable({
  loader: () => import('./views/Financials/List'),
  loading: Loading
});

const MobileLanding = Loadable({
  loader: () => import('./views/MobileLanding/List/List'),
  loading: Loading
});

const MobileLandingAdd = Loadable({
  loader: () => import('./views/MobileLanding/Add/Add'),
  loading: Loading
});

const Category = Loadable({
  loader: () => import('./views/Category/List/List'),
  loading: Loading
});

const CatergoryBannerAdd = Loadable({
  loader: () => import('./views/Category/AddBanner/AddBanner'),
  loading: Loading
});

const CustomerStoryAdd = Loadable({
  loader: () => import('./views/Category/AddCustomerStory/AddCustomerStory'),
  loading: Loading
});

const Blog= Loadable({
  loader: () => import('./views/Blogs/List/List'),
  loading: Loading
});

const AddBlog= Loadable({
  loader: () => import('./views/Blogs/AddBlog/Add'),
  loading: Loading
});


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config

let root = {path: '/', exact: true, name: 'Home', component: Dashboard};
let dashboard = {path: '/dashboard', name: 'Dashboard', component: Dashboard};
let changePassword = {path: '/changepassword', name: 'Admin change Password', component: ChangePassword};
let admin_add = {path: '/admins/add/:id?', name: 'Admin Add', component: AdminAdd};
let admin = {path: '/admins', name: 'Admin', component: Admin};
let coupon_add = {path: '/coupons/add/:id?/:code?', name: 'Coupon Add', component: CouponAdd};
let coupon_add_bulk = {path: '/coupons/bulk/add', name: 'Bulk Coupon Add', component: CouponAdd};
let coupon = {path: '/coupons', name: 'Coupon', component: Coupon};
let partner_add = {path: '/partners/add/:id?', name: 'Partner Add', component: PartnerAdd};
let partner = {path: '/partners', name: 'Partner', component: Partner};
let campaign_add = {path: '/campaigns/add/:id?', name: 'Campaign Add', component: CampaignAdd};
let campaign = {path: '/campaigns', name: 'Campaign', component: Campaign};
let users_add = {path: '/users/add/:id?', name: 'User Add', component: UserAdd};
let users = {path: '/users', name: 'User', component: User};
let products_add = {path: '/products/add/:id?/:pp_id?', name: 'Product Add', component: ProductsAdd};
let products = {path: '/products', name: 'Products', component: Products};
let promobanner_add = {path: '/promobanners/add/:id?', name: 'Banner Add', component: PromobannerAdd};
let promobanner = {path: '/promobanners', name: 'Promobanners', component: Promobanner};
let walletbanner_add = {path: '/wallet_add/:id?', name: 'Wallet Banner Add', component: WalletBannerAdd};
// let referral_add = { path: '/referral-code/add/:id?', name: 'Referral Add', component: ReferralAdd };
let referral = {path: '/referral-code', name: 'Referral Code', component: Referral};
let configuration = {path: '/configuration', name: 'Configuration', component: Configuration};
let sub_order = {path: '/orders/view/:id?/sub-order', name: 'Sub-order Details', component: SubOrder};
let orders_add = {path: '/orders/view/:id?', name: 'Order Details', component: OrdersAdd};
let orders = {path: '/orders', name: 'Order', component: Orders};
let postal_code_add = {path: '/postal-code/add/:id?', name: 'Postal Code Add', component: PostalCodeAdd};
let postal_code = {path: '/postal-code', name: 'Postal Code', component: PostalCode};
// let couriers_add = {path: '/couriers/add/:id?', name: 'Couriers Add', component: CouriersAdd};
let couriers = {path: '/couriers', name: 'Couriers', component: Couriers};
let shipping = {path: '/shipping', name: 'Shipping', component: Shipping};
let reports = {path: '/reports', name: 'Reports', component: Reports};
let financials = {path: '/financials', name: '/Financials', component: Financials};
let coupon_offers_add = {path: '/offers/coupon-offers-add/:id?', name: 'Coupon Offers Add', component: CouponOffersAdd};
let payment_offers_add = {path: '/offers/payment-offers-add/:id?', name: 'Payment Offers Add', component: PaymentOffersAdd};
let card_offers_add = {path: '/offers/card-offers-add/:id?', name: 'Card Offers Add', component: CardOffersAdd};
let offers = {path: '/offers', name: 'Offers', component: Offers};
let mobilelanding = {path: '/mobile-landing', name: 'Mobile Landing Page', component: MobileLanding};
let mobilelanding_add = {path: '/mobile-landing-add', name:'Add Mobile Landing Page', component: MobileLandingAdd};
let category_banner_add = {path: '/category-banner-add', name: 'Category Banner Add', component: CatergoryBannerAdd};
let customer_story_add = {path: '/customer-story-add', name: 'Customer Story Add', component: CustomerStoryAdd};
let category = {path: '/category', name: 'Category', component: Category};
let blog={path: '/blog', name: 'Blog', component: Blog}
let add_blog={path: '/blog-add', name: 'Blog Add', component: AddBlog}

let adminRole;
let token = Helpers.getLocalStorageData('adminToken');
if(token !== null){
  try{
    let decode = JWT.decode(token);
    if (decode !== null ) {
      adminRole = parseInt(decode.userData.AdminRoleId)
    } else {
      adminRole = 0
    }
  } catch(err) {
    adminRole = 0
  }
}

let routes;

if (adminRole === 1) {
  routes = [
    root,
    dashboard,
    admin_add,
    admin,
    users_add,
    users,
    products_add,
    products,
    partner_add,
    partner,
    campaign_add,
    campaign,
    configuration,
    mobilelanding,
    mobilelanding_add,
    coupon_add_bulk,
    coupon_add,
    coupon,
    promobanner_add,
    promobanner,
    walletbanner_add,
    // referral_add,
    referral,
    changePassword,
    sub_order,
    orders_add,
    orders,
    postal_code_add,
    postal_code,
    // couriers_add,
    couriers,
    shipping,
    reports,
    financials,
    coupon_offers_add,
    payment_offers_add,
    card_offers_add,
    offers,
    category,
    category_banner_add,
    customer_story_add,
    blog,
    add_blog
  ]
} else if (adminRole === 2) {
  routes = [
    root,
    dashboard,
    users_add,
    users,
    partner_add,
    partner,
    campaign_add,
    campaign,
    coupon_add_bulk,
    coupon_add,
    coupon,
    promobanner_add,
    promobanner,
    walletbanner_add,
    // referral_add,
    referral,
    changePassword,
    sub_order,
    orders_add,
    orders,
    // couriers_add,
    shipping,
    reports,
    coupon_offers_add,
    payment_offers_add,
    card_offers_add,
    offers,
    mobilelanding,
    mobilelanding_add,
    category,
    category_banner_add,
    customer_story_add,
    blog,
    add_blog
  ]
} else if (adminRole === 3) {
  routes = [
    root,
    dashboard,
    users_add,
    users,
    coupon_add_bulk,
    coupon_add,
    coupon,
    referral,
    changePassword,
    sub_order,
    orders_add,
    orders,
    reports,
    coupon_offers_add,
    payment_offers_add,
    card_offers_add,
    offers,
    mobilelanding,
    mobilelanding_add,
    category,
    category_banner_add,
    customer_story_add,
    blog,
    add_blog
  ]
} else if (adminRole === 4) {
  routes = [
    root,
    dashboard,
    changePassword,
    users_add,
    users,
    sub_order,
    orders_add,
    orders,
    postal_code_add,
    postal_code,
    shipping,
    reports,
  ]
} else if (adminRole === 5) {
  routes = [shipping]
} else {
  routes = [
    root
  ]
}

export default routes;
