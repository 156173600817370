import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container} from 'reactstrap';

import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
import navigation from '../../_nav';
import routes from '../../routes';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import Helpers from "../../service/Helpers";

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigation: navigation.items,
      themeChange : Helpers.getLocalStorageData("theme")
    };

    var matchRoutes = require('react-router-config').matchRoutes;
    const branch = matchRoutes(routes, this.props.location.pathname);
    if(branch.length === 0){
      this.props.history.push("/404")
    }
  }

  themeData =(no) => {
    Helpers.setLocalStorageData("theme",no);
    this.setState({
      themeChange : Helpers.getLocalStorageData("theme")
    });
  };

  render() {

    let that =this;
    return (
      <div className={"app "+this.state.themeChange }>
        <AppHeader fixed>
          <DefaultHeader themeFromHeader={this.themeData} {...that.props}/>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />

            <AppSidebarForm />
            <AppSidebarNav navConfig={navigation} {...that.navigation} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => {
                    return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                        <route.component {...props} />
                      )} />)
                      : (null);
                  },
                )}
                {/*   <Redirect from="/" to="/dashboard" />*/}
              </Switch>
            </Container>
          </main>
          {/*<AppAside fixed hidden>*/}
          {/*<DefaultAside />*/}
          {/*</AppAside>*/}
        </div>
        <AppFooter>
          <DefaultFooter projectName="ZOOMIN" poweredBy={"MINDINVENTORY"}/>
        </AppFooter>
      </div>
    );
  }
}
export default DefaultLayout;
