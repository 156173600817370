import React, {Component} from 'react';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Row
} from 'reactstrap';
import {ADMIN_API_HEADER} from './../../../config';
import {connect} from "react-redux";
import {createSelector} from "reselect";
import {updateUser} from "./../../../service/actions/user-action";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './../../../assets/img/brand/zoominLogo.svg'
import Api from "../../../service/Api";
import ZoominLogo from './../../../assets/img/brand/ZOOMIN_primary_logotype.svg'

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      msg: '',
    };
  }

  login(event) {
    event.preventDefault();
    const {history} = this.props;
    history.push('/login');
  }

  handleSubmit(event) {
    event.preventDefault();
    const {history} = this.props;
    let reqBody = {
      email: this.state.email,
    };
    Api.forgotPassword(reqBody, ADMIN_API_HEADER).then((response) => {
      if (response.status === 200) {
        if (response.data.status === 0) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1500
          });
          setTimeout(function () {
            history.push('/login');
          }, 1500);
        } else {
          this.setState({msg: response.data.message});
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000
          });
        }
      } else {
        this.setState({msg: response.data.message});
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000
        });
      }
    }, err => {
      this.setState({msg: 'Something went wrong!'})
    })
  }

  render() {
    return (
      <div className="app zoomin-theme flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col className="col-md-7 col-sm-10 col-xs-10 col-lg-6 col-xl-5">
              <CardGroup>
                <Card className="p-4 bg-login">
                  <CardBody>
                    <Form onSubmit={evt => this.handleSubmit(evt)}>
                      <div className="text-center">
                        <img src={ZoominLogo} width={200} height={100} alt="zoomin logo"/>
                      </div>
                      <h5 className="text-center fp mb-5">Forgot Password</h5>
                      <p className="text-muted text-center">You will receive an email with a reset password link</p>
                      <InputGroup className="mb-3">
                        {/*<InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"/>
                          </InputGroupText>
                        </InputGroupAddon>*/}
                        <Input type="text" placeholder="Email address" autoComplete="username"
                               className="input-box-login"
                               value={this.state.email} onChange={evt => this.setState({email: evt.target.value})}
                        />
                      </InputGroup>
                      <p className="text-danger" color="danger">{this.state.msg}</p>
                      <Row>
                        <Col xs="12" className="mt-4">
                          <Button className="px-4 submit-btn bg-newcol col-12 border-0 font-weight-bold">Submit</Button>
                        </Col>
                        <Col xs="12" className="text-right">
                          <Button color="link" onClick={evt => this.login(evt)}
                                  className="px-0 forgot-pass">Login</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
        <ToastContainer/>
      </div>
    );
  }
}

const userSelector = createSelector(
  state => state.user,
  user => user,
);

const mapStateToProps = createSelector(
  userSelector,
  (user) => ({
    user
  })
);

const mapActionToProps = {
  onUserUpdate: updateUser
};

export default connect(mapStateToProps, mapActionToProps)(ForgotPassword);
