import React, {Component} from 'react';
import {Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, Row} from 'reactstrap';
import {API_HEADER} from './../../../config';
import Helpers from "./../../../service/Helpers";
import {connect} from "react-redux";
import {createSelector} from "reselect";
import {updateUser} from "./../../../service/actions/user-action";
import logo from './../../../assets/img/brand/zoominLogo.svg'
import Api from "../../../service/Api";
import JWT from "jsonwebtoken";
import ZoominLogo from './../../../assets/img/brand/ZOOMIN_primary_logotype.svg'


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      twofacode: '',
      msg: '',
      codemsg: '',
      shown: false,
      shownForm: true,
    };
  }

  forgotpasswordpage(event) {
    event.preventDefault();
    const {history} = this.props;
    history.push('/forgotpassword');
  }

  handleSubmit(event) {
    event.preventDefault();
    let reqBody = {
      email: this.state.email,
      password: this.state.password,
    };
    Api.login(reqBody, API_HEADER).then((response) => {
      if (response.data.status === 0) {

        this.setState({
          shown: true,
          shownForm: false,
        });
        document.getElementById("2fa").focus()
      } else {
        this.setState({msg: response.data.message})
      }
      if (response.status === 200) {
      }
      else {
        this.setState({msg: response.data.message})
      }
    }, err => {
      this.setState({msg: 'Something went wrong!'})
    })
  }


  handleSubmitCode(event) {
    event.preventDefault();
    let reqBody = {
      twofacode: this.state.twofacode,
      email: this.state.email,
      password: this.state.password,
    };
    Api.twoFacodeVerification(reqBody, API_HEADER).then((response) => {
      if (response.data.status === 0) {
        Helpers.setLocalStorageData('adminToken', response.data.token);
        Helpers.setLocalStorageData("theme", "zoomin-theme");
        // let decode = JWT.decode(response.data.token);
        // Helpers.setLocalStorageData("role",decode.userData.AdminRoleId);
        // this.props.onUserUpdate(response.data.data);
        let adminRole;
        let token = Helpers.getLocalStorageData('adminToken');
        if(token !== null){
          try{
            let decode = JWT.decode(token);
            if (decode !== null ) {
              adminRole = parseInt(decode.userData.AdminRoleId)
            } else {
              adminRole = 0
            }
          } catch(err) {
            adminRole = 0
          }

        }
        if (adminRole === 5){
          window.location = '/shipping';
        }else{
          window.location = '/dashboard';
        }

      } else {
        this.setState({codemsg: response.data.message})
      }
      if (response.status === 200) {
      }
      else {
        this.setState({msg: response.data.message})
      }
    }, err => {
      this.setState({msg: 'Something went wrong!'})
    })
  }

  render() {

    var shown = {
      display: this.state.shown ? "block" : "none"
    };


    var shownForm = {
      display: this.state.shownForm ? "block" : "none"
    };


    return (
      <div className="app zoomin-theme flex-row align-items-center main-page">
        <Container>
          <Row className="justify-content-center">
            <Col className="col-md-7 col-sm-10 col-xs-10 col-lg-6 col-xl-5">
              <CardGroup>
                <Card style={shownForm} className="p-4 bg-login">
                  <CardBody>
                    <Form onSubmit={evt => this.handleSubmit(evt)}>
                      <div className="text-center">
                        <img src={ZoominLogo} width={200} height={100} alt="zoomin logo"/>
                      </div>
                      <p className="text-muted text-center">Log In to your account</p>
                      <InputGroup className="mb-3">
                        {/* <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"/>
                          </InputGroupText>
                        </InputGroupAddon>*/}
                        <Input type="text" placeholder="Email address or Username" autoComplete="username"
                               className="input-box-login"
                               value={this.state.email} onChange={evt => this.setState({email: evt.target.value})}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        {/*<InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"/>
                          </InputGroupText>
                        </InputGroupAddon>*/}
                        <Input type="password" placeholder="Password" autoComplete="current-password"
                               className="input-box-login"
                               value={this.state.password} onChange={evt => this.setState({password: evt.target.value})}
                        />
                      </InputGroup>

                      <p className="text-danger" color="danger">{this.state.msg}</p>
                      <Row>
                        <Col xs="12" className="text-right">
                          <Button color="link" onClick={evt => this.forgotpasswordpage(evt)}
                                  className="px-0 forgot-pass">Forgot password?</Button>
                        </Col>
                        <Col xs="12" className="mt-2">
                          <Button className="submit-btn bg-newcol px-4 col-12 border-0 font-weight-bold">Login</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col className="col-md-7 col-sm-10 col-xs-10 col-lg-6 col-xl-5">
              <CardGroup>
                <Card style={shown} className="p-4 bg-login">
                  <CardBody>
                    <Form onSubmit={evt => this.handleSubmitCode(evt)}>
                      <div className="text-center">
                        <img src={ZoominLogo} width={200} height={100} alt="zoomin logo"/>
                      </div>
                      <p className="text-muted text-center">Please enter 2fa code to login</p>
                      <InputGroup className="mb-3">
                        {/*<InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock"/>
                            </InputGroupText>
                          </InputGroupAddon>*/}
                        <Input type="text" placeholder="2FA code" autoComplete="" className="input-box-login" id="2fa"
                               value={this.state.twofacode}
                               onChange={evt => this.setState({twofacode: evt.target.value})}
                        />
                      </InputGroup>
                      <Row>
                        <div className="col-12 mt-1">
                          <Button className="submit-btn bg-newcol px-4 border-0 font-weight-bold col-12">Submit</Button>
                        </div>
                      </Row>
                      <p className="text-danger mt-2" color="danger">{this.state.codemsg}</p>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const userSelector = createSelector(
  state => state.user,
  user => user,
);

const mapStateToProps = createSelector(
  userSelector,
  (user) => ({
    user
  })
);

const mapActionToProps = {
  onUserUpdate: updateUser
};

export default connect(mapStateToProps, mapActionToProps)(Login);
