import iosImage from "../assets/img/others/ios.png"
import androidImage from "../assets/img/others/android.png"
import webImage from "../assets/img/others/web.png"
import phonePe from "../assets/img/others/phonePe.png"
import paytmImage from "../assets/img/others/paytm.png"
import flipkartImage from "../assets/img/others/flipkart.png"
import logo from "../assets/img/banner/ZOOMIN_primary_logotype.svg"

let media = {};
media.iosImage = iosImage;
media.androidImage = androidImage;
media.phonePeImage = phonePe;
media.webImage = webImage;
media.flipkartImage = flipkartImage;
media.paytmImage = paytmImage;
media.logo = logo;
export default media
