import React, {Component} from 'react';
import {Col, Container, Row} from 'reactstrap';
import axios from "axios";
import {ADMIN_API_URL, ADMIN_API_HEADER} from "../../../config";
import media from "../../../helpers/MediaSources"

class CodOrderConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {msg: ''};
  }

  componentDidMount() {
    let orderId = this.props.match.params.orderId;
    let orderUniqueId = this.props.match.params.orderUniqueId;
    if (orderId !== undefined && orderUniqueId !== undefined) {
      axios.get(`${ADMIN_API_URL}ivr-cod-email-confirmation?order_id=` + orderId + `&order_unique_id=` + orderUniqueId, ADMIN_API_HEADER).then((response) => {
        if (response.status === 200) {
          if (response.data.status === 0) {
            this.setState({
              heading: <span className={"text-success"}>Order Confirmed</span>,
              msg: <span className={"text-success"}>Your Cash on Delivery order is confirmed.</span>
            });

          } else if (response.data.status === 1) {
            this.setState({
              heading: <span className={"text-danger"}>Order Already Confirmed</span>,
              msg: <span className={"text-danger"}>Your Cash on Delivery order is already confirmed. Please check order history for current status.</span>
            });
          } else if (response.data.status === 2) {
            this.setState({
              heading: <span className={"text-danger"}>Invalid Link</span>,
              msg: <span className={"text-danger"}>The order confirmation link you are trying is invalid.</span>
            });
          } else if (response.data.status === 3) {
            this.setState({
              heading: <span className={"text-danger"}>Order Cancelled</span>,
              msg: <span className={"text-danger"}>Your Cash on delivery order has been cancelled. Please visit the help section or email customerservice@zoomin.com for more details.</span>
            });
          }
        } else {
          this.setState({
            heading: <span className={"text-danger"}>Invalid Link</span>,
            msg: <span className={"text-danger"}>The order confirmation link you are trying is invalid.</span>
          });
        }
      })
    } else {
      this.setState({
        heading: <span className={"text-danger"}>Invalid Link</span>,
        msg: <span className={"text-danger"}>The order confirmation link you are trying is invalid.</span>
      })
    }
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" className={"acv"}>
              <div className="clearfix text-center" style={{marginTop: "-50px"}}>
                <img alt="zoomin logo" src={media.logo} style={{}}/>
                <h2 className="pt-3 mt-5">{this.state.heading}</h2>
                <h4 className="pt-3" style={{}}>{this.state.msg}</h4>
                <a className={"login-redirect"} href={"https://zoomin.com/sign-in"}>Click here to login</a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default CodOrderConfirmation;
