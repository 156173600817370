import React, {Component} from 'react';
import {
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Row
} from 'reactstrap';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../../../assets/img/brand/zoominLogo.svg";

class Greetings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      msg: '',
    };
  }

  render() {
    return (
      <div className="app zoomin-theme flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col className="col-md-7 col-sm-10 col-xs-10 col-lg-6 col-xl-7">
              <CardGroup>
                <Card className="p-4 bg-login">
                  <CardBody>
                    <Form onSubmit={evt => this.handleSubmit(evt)}>
                      <div className="text-center">
                        <img src={logo} width={200} height={100} alt="zoomin logo"/>
                      </div>
                      <h5 className="text-center fp mb-5">Congratulations</h5>
                      <p className="text-muted text-center">You have successfully reset your password. Now you can login
                        with the new password.</p>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
        <ToastContainer/>
      </div>
    );
  }
}

export default Greetings;
