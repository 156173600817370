import React, {Component} from 'react';
import {DropdownItem, DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown} from 'reactstrap';
import PropTypes from 'prop-types';

import {AppNavbarBrand, AppSidebarToggler} from '@coreui/react';
import logo from './../../assets/img/brand/zoominLogo.svg'
import minLogo from './../../assets/img/banner/zoomin.png'
import Helpers from "./../../service/Helpers";
import ZoominLogo from './../../assets/img/brand/ZOOMIN_primary_logotype.svg'

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};


class DefaultHeader extends Component {
  logout() {
    Helpers.removeLocalStorageData('adminToken');
    Helpers.removeLocalStorageData('role');
    Helpers.removeLocalStorageData('theme');
    this.props.history.push('/')
  };

  changePassword() {
    this.props.history.push('/changepassword')
  };


  render() {

    // eslint-disable-next-line
    const {children, ...attributes} = this.props;

    return (
        <React.Fragment>
          <AppSidebarToggler className="d-lg-none" display="md" mobile/>
          <AppNavbarBrand
              full={{src: ZoominLogo, width: 130, height: 55, alt: 'CoreUI Logo'}}
              minimized={{src: minLogo, width: 30, height: 22, alt: 'CoreUI Logo'}}
          />
          <AppSidebarToggler className="d-md-down-none" display="lg"/>

          {/*<Nav className="d-md-down-none" navbar>*/}
          {/*<NavItem className="px-3">*/}
          {/*<NavLink href="/">Dashboard</NavLink>*/}
          {/*</NavItem>*/}
          {/*<NavItem className="px-3">*/}
          {/*<NavLink href="#/users">Users</NavLink>*/}
          {/*</NavItem>*/}
          {/*<NavItem className="px-3">*/}
          {/*<NavLink href="#">Settings</NavLink>*/}
          {/*</NavItem>*/}
          {/*</Nav>*/}
          <Nav className="ml-auto" navbar>
            {/*<NavItem className="d-md-down-none">*/}
            {/*<NavLink href="#"><i className="icon-bell"/><Badge pill color="danger">5</Badge></NavLink>*/}
            {/*</NavItem>*/}
            {/*<NavItem className="d-md-down-none">*/}
            {/*<NavLink href="#"><i className="icon-list"/></NavLink>*/}
            {/*</NavItem>*/}
            {/*<NavItem className="d-md-down-none">*/}
            {/*<NavLink href="#"><i className="icon-location-pin"/></NavLink>*/}
            {/*</NavItem>*/}

            <UncontrolledDropdown nav direction="down">
              <DropdownToggle nav>
                <i className="fa fa-cog font-4xl setting-icon">
                </i>
                {/*<img src={'assets/img/avatars/6.jpg'} className="img-avatar" alt="admin@bootstrapmaster.com"/>*/}
              </DropdownToggle>
              <DropdownMenu right style={{right: 'auto', boxShadow: '1px 1px 10px gray', border: 'none'}}>
                <DropdownItem header tag="div"
                              className="card-header text-center text-dark"><strong>Account</strong></DropdownItem>
                {/*<DropdownItem><i className="fa fa-bell-o"/> Updates<Badge color="info">42</Badge></DropdownItem>*/}
                {/*<DropdownItem><i className="fa fa-envelope-o"/> Messages<Badge color="success">42</Badge></DropdownItem>*/}
                {/*<DropdownItem><i className="fa fa-tasks"/> Tasks<Badge color="danger">42</Badge></DropdownItem>*/}
                {/*<DropdownItem><i className="fa fa-comments"/> Comments<Badge color="warning">42</Badge></DropdownItem>*/}
                {/*{<DropdownItem header tag="div" className="text-center"><strong>Settings</strong></DropdownItem>}*/}
                {/*<DropdownItem><i className="fa fa-user"/> Profile</DropdownItem>*/}
                <DropdownItem onClick={() => this.changePassword()}><i className="fa fa-wrench"/> Change
                  Password</DropdownItem>
                {/*<DropdownItem><i className="fa fa-usd"/> Payments<Badge color="secondary">42</Badge></DropdownItem>*/}
                {/*<DropdownItem><i className="fa fa-file"/> Projects<Badge color="primary">42</Badge></DropdownItem>*/}
                {/*<DropdownItem divider/>*/}
                {/*<DropdownItem><i className="fa fa-shield"/> Lock Account</DropdownItem>*/}
                <DropdownItem onClick={() => this.logout()}><i className="fa fa-power-off"/>Logout</DropdownItem>
                <DropdownItem divider/>
                <DropdownItem disabled><b>Change Theme</b></DropdownItem>
                <DropdownItem divider/>
                <DropdownItem onClick={() => this.props.themeFromHeader('zoomin-theme')}><i
                    className="fa fa-moon-o def-th"/>Default theme</DropdownItem>
                <DropdownItem onClick={() => this.props.themeFromHeader("zoomin-dark-theme")}><i
                    className="fa fa-moon-o dark-th"/>Dark Theme</DropdownItem>
                <DropdownItem onClick={() => this.props.themeFromHeader("zoomin-light-theme")}><i
                    className="fa fa-moon-o light-th"/>Light Theme</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          {/*<AppAsideToggler className="d-md-down-none"/>*/}
          {/*<AppAsideToggler className="d-lg-none" mobile />*/}
        </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
