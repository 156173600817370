import React from 'react';
import {Link} from "react-router-dom";
import Lens from "../../../assets/img/404/camera-lens.png"
import "../../../assets/css/404.css"
import {Navbar} from "reactstrap";

class Page404 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorText: '',
      link: '',
      page: ''
    }
  }

  componentDidMount() {
    const error = this.props.match.params.type;
    if (error === 'editor') {
      this.setState({
        errorText: 'The item you looking is not available',
        link: '/dashboard',
        page: 'Cart Page'
      })

    }
    else {
      this.setState({
        errorText: 'Page you are looking for is not available',
        link: '/dashboard',
        page: 'Dashboard'
      })
    }

  }

  render() {
    return (
      <div>
        <Navbar bgColor="#fff"/>
        <div style={{textAlign: 'center'}}>
          <div style={{position: 'relative', display: 'table', margin: '0px auto 0',}}>
            <span className={"num-4"}>4</span>
            <span>
                                <img alt={"0"} src={Lens} className={"lens-0"}/>
                            </span>
            <span className={"num-4"}>4</span>
          </div>

          <div style={{fontSize: '20px', color: '#8393a3'}}><span style={{
            fontWeight: 'bold',
            color: '#ef7937',
            fontSize: '30px'
          }}>Opps! </span>{this.state.errorText ? this.state.errorText : ''}</div>
          <div style={{marginTop: '20px', marginBottom: '50px'}}>Click to go <Link style={{color: '#0c5888'}}
                                                                                   to={this.state.link}>{this.state.page}</Link>
          </div>
        </div>

      </div>
    )
  }
}

export default Page404
