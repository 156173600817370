let AWS = require("aws-sdk")
// console.log(require('dotenv').config())
// require('dotenv').config();

const Aws =(prefix, func)=>{
  AWS.config.update({
    "accessKeyId": process.env.REACT_APP_S3_ACCESS_KEY,
    "secretAccessKey":  process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
    "region": "ap-south-1"
  });
  let s3 = new AWS.S3();
  let params = {
    Bucket: "zoomin-new",
    // Delimiter: "/live-product/",
    Prefix: prefix,
    EncodingType: "url",
  };
  s3.listObjects(params, function (err, response) {
    func(response)
  });
}
export default Aws
