import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import  {PrivateRoute} from './helpers/PrivateRoutes';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './assets/css/style.css'

// Containers
import { DefaultLayout } from './containers';
// Pages
import { Login, Page404, Page500, Register } from './views/Pages';
import ForgotPassword from "./views/Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./views/Pages/ResetPassword";
import ResetPasswordUser from "./views/Pages/ResetPasswordUser";
import Greetings from "./views/Pages/Greetings";
import UserAccountVerification from "./views/Pages/UserAccountVerification";
import OrdersEditor from "./Editor";
import CodOrderConfirmation from "./views/Pages/CodOrderConfirmation";
// import { renderRoutes } from 'react-router-config';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/forgotpassword" name="Forgot Password Page" component={ForgotPassword} />
          <Route exact path="/resetpassword/:token?" name="Reset Password Page" component={ResetPassword} />
          <Route exact path="/reset-password-user/:token?" name="User Reset Password Page" component={ResetPasswordUser} />
          <Route exact path="/account-verification/:token?" name="Account Verification Page" component={UserAccountVerification} />
          <Route exact path="/cod-order-confirmation/:orderId/:orderUniqueId" name="COD Order Confirmation Page"
                 component={CodOrderConfirmation}/>
          <Route exact path="/greetings" name="Greetings" component={Greetings} />
          <Route exact path="/register" name="Register Page" component={Register} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <PrivateRoute exact path="/preview/:orderId/edit/:i/:j" name="Preview Page" component={OrdersEditor} />
          <PrivateRoute exact path="/preview/:orderId/" name="Preview Page" component={OrdersEditor} />
          <PrivateRoute path="/" name="Home" component={DefaultLayout} />

        </Switch>
      </BrowserRouter>
    );
  }
}

const userSelector = createSelector(
  state => state.user,
  user => user,
);

const mapStateToProps = createSelector(
  userSelector,
  (user) => ({
    user
  })
);
export default connect(mapStateToProps)(App);
