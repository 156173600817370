import React, {Component} from 'react';
import {Col, Container, Row} from 'reactstrap';
import axios from "axios";
import {API_URL, ADMIN_API_HEADER} from "../../../config";
import media from "../../../helpers/MediaSources"

class UserAccountVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {msg: ''};
  }

  componentDidMount() {
    let token = this.props.match.params.token;
    if (token !== undefined) {
      let reqBody = {
        "token": token,
      };

      axios.post(`${API_URL}user-account-verification`, reqBody,
        ADMIN_API_HEADER).then((response) => {
        if (response.status === 200) {
          if (response.data.status === 0) {
            this.setState({
              heading: <span className={"text-success"}>Verified</span>,
              msg: <span className={"text-success"}>Your email account verified successfully</span>
            });

          } else {
            this.setState({
              heading: <span className={"text-danger"}>Invalid Link</span>,
              msg: <span className={"text-danger"}>Please make sure you clicked on the correct email verification link, if in case you received multiple emails.</span>
            });
          }
        }
        else {
          this.setState({
            heading: <span className={"text-danger"}>Invalid Link</span>,
            msg: <span className={"text-danger"}>Please make sure you clicked on the correct email verification link, if in case you received multiple emails.</span>
          });
        }
      })
    } else {
      this.setState({
        heading: <span className={"text-danger"}>Invalid Link</span>,
        msg: <span className={"text-danger"}>Please make sure you clicked on the correct email verification link, if in case you received multiple emails.</span>
      })
    }
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" className={"acv"}>
              <div className="clearfix text-center" style={{marginTop: "-50px"}}>
                <img alt="zoomin logo" src={media.logo} style={{}}/>
                <h2 className="pt-3 mt-5">{this.state.heading}</h2>
                <h4 className="pt-3" style={{}}>{this.state.msg}</h4>
                {/*<a className={"login-redirect"} href={"https://www.zoomin.com/sign-in"}>Click here to login</a>*/}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default UserAccountVerification;
