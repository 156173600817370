import React, {Component} from 'react';
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  CardHeader
} from 'reactstrap';
import {ADMIN_API_HEADER} from './../../../config';
import {API_ROOT} from "../../../config";
import {connect} from "react-redux";
import {createSelector} from "reselect";
import {updateUser} from "./../../../service/actions/user-action";
import SimpleReactValidator from "simple-react-validator";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css' //Import CSS
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import axios from 'axios';
import Api from "./../../../service/Api";
import Helpers from "./../../../service/Helpers";
// import Web from "../../assets/img/others/web.png"
// import Android from "../../assets/img/others/android.png"
// import iOS from "../../assets/img/others/ios.png"
// import economyImg from "../../assets/img/others/economy.svg"
// import expressImg from "../../assets/img/others/express.svg"
// import standardImg from "../../assets/img/others/standard.svg"
// import codImg from "../../assets/img/others/cod.svg"
import {confirmAlert} from "react-confirm-alert";
import Moment from "react-moment";
import { element } from 'prop-types';

class MobileLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data_blockList:[],
      landing_id:"",
    };

}

deleteBanner(id) {
  let reqBody = {
    "mobile_landing_page_id": id,
  };

  confirmAlert({
    title: 'Delete',
    message: 'Are you sure to delete the banner?',
    buttons: [
      {
        label: 'Confirm',
        onClick: () =>
          Api.deleteMobileLandingPage(reqBody, ADMIN_API_HEADER).then((response) => {
            console.log(response.data)
            if (response.status === 200) {
             
              if (response.data.status === 0) {
                Api.getMobileLandingPage({
                  
                }, ADMIN_API_HEADER).then((response) => {
                toast.success(response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1500
                  });
                  let json_data=[]
                          for (let index = 0; index < response.data.data.length; index++) {
                              json_data.push(response.data.data[index].json_data)
                              // json_data[index].parent_id=response.data.data[index].id
                          }
                  this.setState({
                    data_blockList:json_data,
                    
                  });
                })
              } else {
                toast.error(response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000
                });
              }
            } 
                  else {
                toast.error(response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000
                });
              }
             
          })
      },
      {
        label: 'Cancel',
        onClick: () => ''
      }
    ]
  })
}


  render() {
    const {data_blockList,loading , landing_id}=this.state;
    // var landing_id
    return (
      <div>
        {Helpers.BREADCRUMB([{name: "Home", path: '/dashboard', active: true}, {
          name: "Mobile Landing",
          path: '',
          active: false
        }])}
        <div className="animated fadeIn">
        <div className="card-body">
          <Card>
            <CardHeader>
              <b>Mobile Landing Page</b>
            </CardHeader>
            <CardBody>
              <div className="react-bs-table-tool-bar ">
                    <div className="row justify-content-end btnrows mb-3">
                      <div className="col-xs-3 mt-2 mt-sm-0 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                        <button aria-pressed="true" className="btn bg-faded-reverse btn-block active"
                                onClick={() => this.props.history.push('/mobile-landing-add')}>
                          <i className="fa fa-plus">
                          </i>
                          <b> New Banner</b>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="react-bs-table-container">
                    <div className="react-bs-table-tool-bar">

                    </div>

                    <ReactTable
                      getNoDataProps={() => ({style: {display: 'none'}})}
                      columns={[
                        {
                          Header: "#",
                          // sortable: true,
                          id: "row",
                          Cell: (row) => {
                            return <div>{row.index + 1}</div>;
                          },
                          width: 70,
                        },
                        
                        {
                          Header: "BANNER IMAGE",
                          accessor: "banner_image",
                          sortable: false,
                          Cell: (row) => {
                            return <div>
                              <img className="list-image-large"
                                   alt={`${row.original.banner_image}`}
                                   src={`${row.original.banner_image}`}/>
                            </div>;
                          },
                         
                          width: 170,
                        },

                        {
                          Header: "BANNER URL",
                          accessor: "banner_url",
                          sortable: false,
                          width: 150
                        },

                        {
                          Header: "INTRO TEXT",
                          accessor: "intro_text",
                          sortable: false,
                          width: 180,
                        },
                        {
                          Header: "LANDING PAGE URL",
                          accessor: "landing_url",
                          sortable: true,
                           width: 150,
                        },
                        {
                          Header: "LANDING PAGE TITLE",
                          accessor: "landing_title",
                          sortable: true,
                           width: 150,
                        },
                        // {
                        //   Header: "BUTTON URL",
                        //   sortable: false,
                        //   accessor: "button_url",
                          
                        //   width: 170,
                        //   style: {'whiteSpace': 'unset'}
                        // },
                        // {
                        //   Header: "BUTTON TEXT",
                        //   sortable: false,
                        //    accessor: "button_text",
                        //    width:150
                        // },
                        // {
                        //   Header: "DESCRIPTION",
                        //   sortable: false,
                        //   accessor: "block_description",
                        //   width:200
                        // },
                        {
                          Header: "ACTIONS",
                          sortable: false,

                          Cell: row => (
                            <div className="align-items-center m-sm-0 row justify-content-center">
                              <div col="6" className="col-4">
                                <i className="font-2xl fa fa-edit edit-icon" title="View"
                                   onClick={() => this.props.history.push({
                                    pathname: '/mobile-landing-add/',
                                    state:{
                                      main_id:row.original["parent_id"],
                                      blockList:row.original.blockList
                                    }
                                    })
                                  }
                                   >
                                </i>
                              </div>

                              <div col="6" className="col-3">
                                <i className="font-2xl fa fa-trash del-icon" title="Delete"
                                   onClick={() => this.deleteBanner(row.original["parent_id"])}>
                                </i>
                              </div>
                              
                            </div>
                          ),
                          width: 150
                        }
                      ]}
                      manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                      data={data_blockList}
                      //resizable={false}
                      // pages={pages} // Display the total number of pages
                       loading={loading} // Display the loading overlay when we need it
                      // defaultPageSize={10}
                      // showPageSizeOptions={false}
                      showPaginationBottom={false}
                      minRows={0}
                      className="-striped -highlight"
                      onFetchData={(state, instance) => {
                        // show the loading overlay
                        this.setState({loading: true});
                        // fetch your data
                        Api.getMobileLandingPage({
                          
                        }, ADMIN_API_HEADER).then((res) => {
                          let json_data=[]
                          for (let index = 0; index < res.data.data.length; index++) {
                              json_data.push(res.data.data[index].json_data)
                              json_data[index].parent_id=res.data.data[index].id 
                          }
                         // console.log(json_array);
                          // const json_data=res.data.data.map(el=>el.json_data)
                         // const data_map= res.data.data[0].json_data.blockList
                          console.log(json_data)
                          //landing_id=res.data.data
                          // console.log(landing_id)
                          this.setState({
                           data_blockList:json_data,
                          // landing_id:res.data.data[0].id,
                            loading: false,
                            
                          });
                          // console.log("id",landing_id)
                          
                         console.log("res",res.data.data);
                        })
                      }}
                    />
                    <div className="s-alert-wrapper">
                    </div>
                  </div>
              </CardBody>
              </Card>
                </div>
          <ToastContainer/>
        </div>
      </div>
    );
  }
}

// const userSelector = createSelector(
//   state => state.user,
//   user => user,
// );

// const mapStateToProps = createSelector(
//   userSelector,
//   (user) => ({
//     user
//   })
// );

// const mapActionToProps = {
//   onUserUpdate: updateUser
// };

// export default connect(mapStateToProps, mapActionToProps)(Configuration);
export default MobileLanding;